import classes from '../Advertisement/RightSide_Home.module.css';
import DataGathering from '../Common/DataGathering';

const RightSide_Home = () => {
    return ( 
        <div className={classes.mainDiv}>
             <DataGathering></DataGathering>
            <span>Featured</span>
        </div>
     );
}
 
export default RightSide_Home;